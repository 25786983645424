import React from 'react';
import Carousel from './components/Carousel';

export default function HomeRoute() {

  
  return (
    <>
      <Carousel />  
    </>
  )
}
